<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color=""
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text
      v-if="
        !loading_user &&
        !providers_loading &&
        !insurances_loading &&
        !permissions_loading
      "
    >
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-select
                      label="Rol*"
                      v-model="data.role_id"
                      :rules="rules.required"
                      :items="roles"
                      item-value="id"
                      :item-text="(item) => item.rol"
                      :loading="roles_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Nombre de usuario*"
                      v-model="data.email"
                      :rules="rules.name"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      v-if="store_mode"
                      label="Contraseña*"
                      v-model="data.password"
                      :rules="rules.password"
                      :type="show_password ? 'text' : 'password'"
                      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show_password = !show_password"
                      autocomplete="new-password"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      :label="`Correo electrónico${data.mfa ? '*' : ''}`"
                      v-model="data.email2"
                      :rules="data.mfa ? rules.email : rules.emailNoRequired"
                      type="email"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Tope generacion carta"
                      v-model="data.amount_letter"
                      type="number"
                      :rules="rules.required"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-select
                      label="Número de revisión*"
                      v-model="data.letters_review"
                      :items="letters_reviews"
                      item-value="id"
                      :item-text="(item) => item.name"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Nombre en firma"
                      v-model="data.name_signature"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="2">
                    <v-file-input
                      v-model="data.document_file_0"
                      counter
                      truncate-length="15"
                      label="Firma digital"
                      prepend-icon=""
                      show-size
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="1">
                    <v-switch
                      class="mt-0"
                      v-if="data.signature"
                      label="Eliminar"
                      v-model="data.delete_signature"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Verificación 2 pasos'"
                      v-model="data.mfa"
                      dense
                    />
                  </v-col>
                  <v-col
                    v-if="data.role_id == 6 || data.role_id == 8"
                    cols="12"
                    xs="12"
                    md="3"
                    class="pb-0"
                  >
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'R. general Online'"
                      v-model="data.report_general"
                      dense
                    />
                  </v-col>
                  <v-col
                    v-if="data.role_id == 6 || data.role_id == 8"
                    cols="12"
                    xs="12"
                    md="3"
                    class="pb-0"
                  >
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'R. restitución Online'"
                      v-model="data.report_restitution"
                      dense
                    />
                  </v-col>
                  <v-col
                    v-if="data.role_id == 6 || data.role_id == 8"
                    cols="12"
                    xs="12"
                    md="3"
                    class="pb-0"
                  >
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Columna tipo asegurado Online'"
                      v-model="data.report_insured_type"
                      dense
                    />
                  </v-col>
                  <v-col
                    v-if="data.role_id == 8"
                    cols="12"
                    xs="12"
                    md="3"
                    class="pb-0"
                  >
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Búsqueda por contratante Online'"
                      v-model="data.find_for_contractor"
                      dense
                    />
                  </v-col>
                  <v-col v-if="data.role_id == 13" cols="12" xs="12" md="3">
                    <v-select
                      label="Formato INFO*"
                      v-model="data.pharmacy_format_id"
                      :items="pharmacy_formats"
                      :loading="pharmacy_formats_ldg"
                      item-value="id"
                      :item-text="(itm) => itm.pharmacy_format"
                      :rules="rules.required"
                      dense
                    />
                  </v-col>
                  <v-col v-if="data.role_id == 16" cols="12" xs="12" md="3">
                    <v-select
                      label="Contratante*"
                      v-model="data.contractor_id"
                      :rules="rules.required"
                      :items="contractors"
                      item-value="id"
                      :item-text="(item) => item.name"
                      :loading="contractors_ldg"
                      @change="getUserOfficeAddresses(true)"
                      dense
                    />
                  </v-col>
                  <v-col v-if="data.role_id == 13" cols="12" xs="12" md="3">
                    <v-select
                      label="Red de proveedores*"
                      v-model="data.provider_brand_id"
                      :rules="rules.required"
                      :items="provider_brands"
                      item-value="id"
                      :item-text="(item) => item.provider_brand"
                      :loading="provider_brands_ldg"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.role_id != null">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'ASEGURADORAS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="12">
                    <v-autocomplete
                      label="Aseguradora*"
                      v-model="data.insurances"
                      :rules="rules.required"
                      :items="insurances"
                      item-value="id"
                      :item-text="(item) => item.name"
                      :loading="insurances_loading"
                      multiple
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            v-if="
              data.role_id != null &&
              data.role_id !== 12 &&
              data.role_id !== 8 &&
              data.role_id !== 13 &&
              data.role_id !== 16
            "
          >
            <v-card :loading="providers_loading" :disabled="providers_loading">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'PROVEEDORES'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="info"
                        icon
                        @click.prevent="providersSelectAll"
                      >
                        <v-icon
                          v-text="
                            `mdi-${
                              providers_all ? 'collapse-all' : 'expand-all'
                            }`
                          "
                        />
                      </v-btn>
                    </template>
                    <span
                      v-text="
                        `${providers_all ? 'Quitar' : 'Seleccionar'} todos`
                      "
                    />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="12">
                    <v-autocomplete
                      label="Proveedor*"
                      v-model="data.providers"
                      :items="providers"
                      item-value="id"
                      :item-text="(item) => item.trade_name"
                      multiple
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.role_id == 6 || data.role_id == 8">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'CONTRATANTES'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="12">
                    <v-autocomplete
                      v-if="data.find_for_contractor"
                      label="Contratante*"
                      v-model="data.find_contractor_id"
                      :rules="rules.required"
                      :items="contractors"
                      item-value="id"
                      :item-text="(v) => v.name"
                      :loading="contractors_ldg"
                      dense
                    />
                    <v-autocomplete
                      v-else
                      label="Concentrador*"
                      v-model="data.contractor_brands"
                      :rules="rules.required"
                      :items="contractor_brands"
                      item-value="id"
                      :item-text="(v) => v.contractor_brand"
                      :loading="contractor_brands_loading"
                      multiple
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            v-for="(insurance, i) in data.insurances"
            :key="i"
            v-if="data.role_id == 12"
          >
            <v-card :loading="providers_loading" :disabled="providers_loading">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span
                    class="text-caption"
                    v-text="getInsuranceName(insurance) + ' (PROVEEDORES)'"
                  />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="info"
                        icon
                        @click.prevent="providersSelectAll"
                      >
                        <v-icon
                          v-text="
                            `mdi-${
                              providers_all ? 'collapse-all' : 'expand-all'
                            }`
                          "
                        />
                      </v-btn>
                    </template>
                    <span
                      v-text="
                        `${providers_all ? 'Quitar' : 'Seleccionar'} todos`
                      "
                    />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="12">
                    <v-autocomplete
                      label="Proveedor*"
                      v-model="data.insurance_providers[insurance]"
                      :items="providers"
                      item-value="id"
                      :item-text="(item) => item.trade_name"
                      multiple
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.role_id != null && data.role_id == 16">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'OFICINAS DE CONTRATANTE'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="12">
                    <v-autocomplete
                      label="Oficina(s)*"
                      v-model="data.txt_user_office_addresses"
                      :rules="rules.required"
                      :items="user_office_addresses"
                      item-value="id"
                      :item-text="(item) => item.office_address"
                      :loading="user_office_addresses_ldg"
                      multiple
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="data.role_id != null" cols="12" xs="12" md="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'PERMISOS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="12">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="item in data.permissions"
                            :key="item.id"
                            class="my-0 py-0"
                            v-if="
                              item.interface_id != 37 ||
                              (item.interface_id == 37 && login.role_id == 1)
                            "
                          >
                            <td class="my-0 py-0">
                              <v-icon small v-text="item.icon" />
                              {{ item.interface_name }}
                            </td>
                            <td>
                              <v-checkbox
                                class="mt-0 pt-0"
                                v-model="item.read"
                                color="success"
                                label="Ver"
                              />
                            </td>
                            <td>
                              <v-checkbox
                                class="mt-0 pt-0"
                                v-model="item.create"
                                color="success"
                                label="Crear"
                              />
                            </td>
                            <td>
                              <v-checkbox
                                class="mt-0 pt-0"
                                v-model="item.update"
                                color="success"
                                label="Editar"
                              />
                            </td>
                            <td>
                              <v-checkbox
                                class="mt-0 pt-0"
                                v-model="item.delete"
                                color="success"
                                label="Eliminar"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" xs="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import FileInputAvatar from "../../components/FileInputAvatar.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    FileInputAvatar,
    FaqDlg,
  },
  data() {
    return {
      page_route: "user",
      page_title: "Usuario",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        name: "",
        email: "",
        password: "",
        amount_letter: "0",
        letters_review: 0,
        permissions: [],
        insurance_providers: [],
        insurances: [],
        email2: null,
        mfa: false,
        report_general: false,
        report_restitution: false,
        report_insured_type: false,
        find_for_contractor: false,
        find_contractor_id: null,
        contractor_brands: [],
        providers: [],
        pharmacy_format_id: null,
        txt_user_office_addresses: [],
        contractor_id: null,
        provider_brand_id: null,
      },
      rules: rules(),
      letters_reviews: [
        {
          id: 0,
          name: "N/A",
        },
        {
          id: 1,
          name: "1",
        },
        {
          id: 2,
          name: "2",
        },
        {
          id: 3,
          name: "3",
        },
        {
          id: 9,
          name: "TODAS",
        },
      ],
      roles: [],
      roles_loading: true,
      show_password: false,
      url: "",
      providers: [],
      providers_loading: true,
      insurances: [],
      insurances_loading: true,
      permissions_loading: true,
      items: [],
      interfaces: [],
      providers_all: false,
      loading_user: true,
      contractor_brands: [],
      contractor_brands_loading: true,
      contractors: [],
      contractors_ldg: true,
      provider_brands: [],
      provider_brands_ldg: true,
      pharmacy_formats: [],
      pharmacy_formats_ldg: true,
      contractors: [],
      contractors_ldg: true,
      user_office_addresses: [],
      user_office_addresses_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getInsuranceName(insurance) {
      return this.insurances.find((x) => x.id === insurance).name;
    },
    handleSubmit() {
      //validate form data
      if (this.$refs.form_data.validate()) {
        if (
          this.data.role_id == 16 ||
          (this.data.insurances && this.data.insurances.length > 0)
        ) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma la ${
                  this.store_mode ? "creación" : "edición"
                } del registro?`
              )
            )
            .then((rsp) => {
              if (rsp.isConfirmed) {
                this.loading = true;
                //store or update icon
                storeUpdate(
                  "general/users",
                  this.login.token,
                  this.id,
                  this.data,
                  this.login.id
                ).then((rsp) => {
                  this.$swal.fire(
                    msgAlert(rsp.success ? "success" : "error", rsp.message)
                  );

                  rsp.success
                    ? this.$router.push({
                        name: `${this.page_route}${
                          this.store_mode ? "" : ".view"
                        }`,
                        params: { id: this.id },
                      })
                    : console.log(rsp.message);
                });

                this.loading = false;
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Se deben de registrar 1 o más proveedores y aseguradoras"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    providersSelectAll() {
      this.providers_all = !this.providers_all;

      this.providers_loading = false;

      let arr = [];

      if (this.providers_all) {
        for (var v of this.providers) {
          arr.push(v.id);
        }
      }

      this.data.providers = arr;

      this.$forceUpdate();
      this.providers_loading = false;
    },
    getUserOfficeAddresses(clear) {
      if (clear) {
        this.data.txt_user_office_addresses = [];
      }

      this.user_office_addresses_ldg = true;

      Axios.get(
        URL_API + "/user/insureds/contractors/" + this.data.contractor_id,
        headersToken(this.login.token)
      ).then((rsp) => {
        if (rsp.data.success) {
          this.user_office_addresses = rsp.data.data;
          this.user_office_addresses_ldg = false;
        }
      });
    },
  },
  watch: {
    "$data.data.insurances": {
      handler: function () {
        if (
          this.data.insurance_providers[
            this.data.insurances[this.data.insurances.length - 1]
          ] === undefined
        ) {
          this.data.insurance_providers[
            this.data.insurances[this.data.insurances.length - 1]
          ] = [];
        }
      },
      deep: true,
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((rsp) => {
      this.faqs = rsp.data.data;
      this.faqs_ldg = false;
    });

    catalog("pharmacy_formats", this.login.token).then((rsp) => {
      this.pharmacy_formats = rsp;
      this.pharmacy_formats_ldg = false;
    });

    catalog("roles", this.login.token).then((rsp) => {
      this.roles = rsp;
      this.roles_loading = false;
    });

    catalog("interfaces", this.login.token, "interface_name").then((rsp) => {
      for (var i = 0; i < rsp.length; i++) {
        this.interfaces.push({
          id: null,
          interface_id: rsp[i].id,
          icon: rsp[i].icon,
          active: true,
          active_list: false,
          create: false,
          read: false,
          update: false,
          delete: false,
          interface_name: rsp[i].interface_name,
        });
        this.data.permissions = this.interfaces;
      }
      this.permissions_loading = false;
    });

    index("providers", this.login.token, [
      {
        name: "active",
        value: 1,
      },
      {
        name: "id",
        value: this.login.id,
      },
    ]).then((rsp) => {
      this.providers = rsp.data;
      this.providers_loading = false;
    });

    index("contractors", this.login.token, [
      {
        name: "active",
        value: 1,
      },
      {
        name: "id",
        value: this.login.id,
      },
    ]).then((rsp) => {
      this.contractors = rsp.data;
      this.contractors_ldg = false;
    });

    index("insurances", this.login.token, [
      {
        name: "active",
        value: 1,
      },
      {
        name: "id",
        value: this.login.id,
      },
    ]).then((rsp) => {
      this.insurances = rsp.data;
      this.insurances_loading = false;
    });

    Axios.get(
      URL_API + "/contractors/brands",
      headersToken(this.login.token)
    ).then((rsp) => {
      if (rsp.data.success) {
        this.contractor_brands = rsp.data.data;
        this.contractor_brands_loading = false;
      } else {
        console.log(rsp.data.message);
      }
    });

    Axios.get(URL_API + "/contractors", headersToken(this.login.token)).then(
      (rsp) => {
        this.contractors = rsp.data.data;
        this.contractors_ldg = false;
      }
    );

    Axios.get(
      URL_API + "/providers/brands",
      headersToken(this.login.token)
    ).then((rsp) => {
      this.provider_brands = rsp.data.data;
      this.provider_brands_ldg = false;
    });

    if (!this.store_mode) {
      this.loading = true;

      show("general/users", this.login.token, this.id).then((rsp) => {
        this.data = rsp;
        let keys = Object.keys(rsp.insurance_providers);
        let insurance_providers = [];
        for (var i = 0; i < keys.length; i++) {
          insurance_providers[keys[i]] = Object.values(
            rsp.insurance_providers[keys[i]]
          );
        }
        this.data.insurance_providers = insurance_providers;
        this.loading = false;
        for (var i = 0; i < this.interfaces.length; i++) {
          var add = true;
          for (var j = 0; j < this.data.permissions.length; j++) {
            if (
              this.interfaces[i].interface_id ==
              this.data.permissions[j].interface_id
            )
              add = false;
          }
          if (add) this.data.permissions.push(this.interfaces[i]);
        }

        if (this.data.role_id == 16) {
          this.getUserOfficeAddresses(false);
        }

        this.loading_user = false;
      });
    } else {
      this.loading_user = false;
    }
  },
};
</script>